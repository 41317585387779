




































import { Vue, Component, Prop } from "vue-property-decorator";
import { LiveSessionInfoProperties } from "./CommonLiveSessionProperties";
import { AlertDialogProperties } from "@/components/shared/AlertDialogView.vue";
import DateUtil from "@/utilities/DateUtil";

@Component({})
export default class LiveSessionDataView extends Vue {
    @Prop() private properties: LiveSessionInfoProperties;
    @Prop() private alertDialog: AlertDialogProperties;

    public get liveSession() {
        return this.properties.liveSession;
    }

    public get dateTimeFormat() {
        return this.properties.dateTimeFormat;
    }

    public get status() {
        const v = this.liveSession.status;

        if (v === "Preparing") {
            return this.$t("text.preparing");
        } else if (v === "Started") {
            return this.$t("text.started");
        } else if (v === "Ended") {
            return this.$t("text.ended");
        } else if (v === "Closed") {
            return this.$t("text.closed");
        }

        return this.$t(v);
    }

    public get sessionDate() {
        const v = this.liveSession.sessionDate;
        return v ? DateUtil.format(new Date(v), this.dateTimeFormat) : "";
    }

    public get startedDate() {
        const v = this.liveSession.startedDate;
        return v ? DateUtil.format(new Date(v), this.dateTimeFormat) : "";
    }

    public get endedDate() {
        const v = this.liveSession.endedDate;
        return v ? DateUtil.format(new Date(v), this.dateTimeFormat) : "";
    }

    public async addComment() {
        await this.properties.events.fire("add-comment", {
            displayName: "Username",
            message: "This is a test message.",
            tempId: Math.random()
        });
    }
}
